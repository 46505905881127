var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Twitter = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", d: "M22 5.894a7.9 7.9 0 0 1-2.244.638A4 4 0 0 0 21.469 4.3a7.6 7.6 0 0 1-2.47.978A3.84 3.84 0 0 0 16.154 4c-2.157 0-3.893 1.814-3.893 4.038 0 .32.026.628.09.92-3.238-.163-6.103-1.772-8.028-4.222a4.2 4.2 0 0 0-.533 2.04c0 1.399.694 2.638 1.73 3.356a3.74 3.74 0 0 1-1.76-.498v.045c0 1.962 1.35 3.591 3.12 3.967-.316.09-.662.132-1.02.132-.25 0-.502-.014-.738-.069.505 1.6 1.937 2.775 3.64 2.813A7.65 7.65 0 0 1 3 18.188 10.7 10.7 0 0 0 8.976 20c7.167 0 11.086-6.154 11.086-11.488q-.001-.265-.014-.522A7.9 7.9 0 0 0 22 5.894" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Twitter;
